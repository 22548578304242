<template>
  <div>
    <div class="container">
      <div>
        <div class="section text-center">
          <div class="buttons">
            <button class="button is-primary" @click="copy">Copy</button>
          </div>
          <div>
            <textarea v-model="generatedUrls" class="textarea is-primary" rows="10" cols="30"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',

  data() {
		return {
      generatedUrls: []
		}
  },

  methods: {
    copy: function () {
      let textarea = document.querySelector('textarea');
      textarea.innerHTML = this.generatedUrls;
      textarea.select();
      let result = document.execCommand('copy');
      return result;
    }
  },

  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let number = params?.n ? params?.n : 10;

    let tempText = ``
    for (let i = 1; i <= number; i++) {
      let text = `https://example.org/test${i}`
      tempText = `${tempText}\n${text}`
    }
    this.generatedUrls = tempText.substring(1)
  }
}

</script>

<style scoped>

</style>
